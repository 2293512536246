<template>
  <div
    class="md:2/3 mx-auto w-full p-5 text-center md:mt-24 md:max-w-[350px] md:rounded-xl md:bg-white md:p-4 md:shadow-drop-lg"
  >
    <LoadingScreen
      v-show="isLoading"
      class="max-w-2xl"
      :fullscreen-on-mobile="$viewport.isLessThan('md')"
    />
    <button class="mb-6 flex" @click="goBack">
      <StaticPictogram name="arrow-left" size="lg" />
    </button>

    <h2 class="mb-6 text-2xl">
      <template v-if="isFinished">
        {{
          $t(`account.add_pets_form.finished.title`, {
            [currentStep.translationKey]: currentPet.name,
          })
        }}
      </template>
      <template v-else-if="error">
        {{ $t('error.something_went_wrong') }}
      </template>
      <template v-else-if="currentStep?.field">
        {{
          $t(`account.add_pets_form.${currentStep.name}.title`, {
            [currentStep.translationKey]: currentStep.fieldCase
              ? currentPet[currentStep.field][currentStep.fieldCase]()
              : currentPet[currentStep.field],
          })
        }}
      </template>
      <template v-else>
        {{ $t(`account.add_pets_form.${currentStep.name}.title`) }}
      </template>
    </h2>
    <div v-if="isFinished" class="mt-1 flex flex-1 flex-col">
      <StaticPictogram
        v-if="currentPet.icon"
        :name="currentPet.icon"
        custom-class="mx-auto mb-6 size-20"
      />
      <p class="mb-6 text-md text-black/87">
        {{ $t('account.add_pets_form.now_you_will_recieve_offers') }}
      </p>
      <Button
        class="mt-3 w-full"
        type="button"
        @click="reset(), sendTrackingEvent(PetFormStep.AddMore)"
      >
        {{ $t('account.add_pets_form.add_more') }}
      </Button>
      <Button
        class="mt-3 w-full"
        type="button"
        color="transparentOutlined"
        @click="leave"
      >
        {{ $t('account.continue_shopping') }}
      </Button>
    </div>
    <div v-else-if="error">
      <!-- eslint-disable vue/no-v-html -->
      <p
        class="mb-6 text-md text-black/87"
        v-html="$t('error.contact_customer_support')"
      />
      <!-- eslint-enable vue/no-v-html -->
      <Button class="mt-3 w-full" type="button" @click="reset">
        {{ $t('account.add_pets_form.add_pet') }}
      </Button>
      <Button
        class="mt-3 w-full"
        type="button"
        color="transparentOutlined"
        @click="leave"
      >
        {{ $t('account.continue_shopping') }}
      </Button>
    </div>
    <VeeForm
      v-else
      v-slot="{ meta }"
      class="mt-1 flex flex-1 flex-col"
      @submit="next"
    >
      <div v-if="currentStep.name === 'type'" class="text-black/87">
        <div
          class="rounded-lg border border-black/6 bg-white py-3 md:rounded-xl md:py-1"
        >
          <AccountDashboardTab
            v-for="tab in dogAndCatTabs"
            :key="tab.name"
            :tab="tab"
            :icon-size="'2xl'"
            :border-bottom="dogAndCatTabs.at(-1) !== tab"
            :show-arrow-on-desktop="true"
            @set-selected-tab="setPetTab($event)"
          />
        </div>
        <div
          class="mt-6 rounded-lg border border-black/6 bg-white py-3 md:rounded-xl md:py-1"
        >
          <AccountDashboardTab
            v-for="tab in otherPetsTabs"
            :key="tab.name"
            :tab="tab"
            :border-bottom="otherPetsTabs.at(-1) !== tab"
            :show-arrow-on-desktop="true"
            @set-selected-tab="setPetTab($event)"
          />
        </div>
      </div>
      <div v-else>
        <InputField
          v-model.trim="currentPet[currentStep.name]"
          :name="`pet_${currentStep.name}`"
          :type="currentStep.type"
          :autocomplete="`pet_${currentStep.name}`"
          rules="required"
        />
        <Button
          class="mt-6 w-full"
          :loading="isSubmitting"
          :disabled="!meta.valid"
        >
          {{ $t('account.add_pets_form.next') }}
        </Button>
      </div>
    </VeeForm>
  </div>
</template>
<script setup lang="ts">
import type { Props as StaticPictogramProps } from '~/components/ui/reactive/StaticPictogram.vue';
import { usePetStore, PetFormStep } from '~/stores/pet';
import { useAuthStore } from '~/stores/auth';
import { addPetEvent } from '~/utils/tracking-utils';

const gtm = useGtm();

const petStore = usePetStore();
const authStore = useAuthStore();

const { petTypes } = storeToRefs(petStore);
const { user } = storeToRefs(authStore);

type Step = {
  name: PetFormStep;
  type: 'text' | 'date';
  field: string;
  fieldCase?: string;
  translationKey?: string;
};

const isSubmitting = ref(false);
const isFinished = ref(false);
const error = ref(false);
const isLoading = ref(false);

const steps: Array<Step> = [
  { name: PetFormStep.Type, type: 'text', field: '' },
  {
    name: PetFormStep.Name,
    type: 'text',
    field: 'type',
    fieldCase: 'toLowerCase',
    translationKey: 'petType',
  },
  {
    name: PetFormStep.Birthdate,
    type: 'date',
    field: 'name',
    translationKey: 'petName',
  },
];

if (!petTypes.value.length) {
  isLoading.value = true;
  await petStore.getPetTypes();
  isLoading.value = false;
}

const dogAndCatTabs = computed(() =>
  petTypes.value
    .filter((pet) => pet.key === 'dog' || pet.key === 'cat')
    .map((pet) => ({ ...pet, icon: pet.key }))
);
const otherPetsTabs = computed(() =>
  petTypes.value.filter((pet: any) => pet.key !== 'dog' && pet.key !== 'cat')
);

const currentStep: Ref<Step> = ref(steps[0]);
const stepIndex = computed(() =>
  steps.findIndex((step) => step.name === currentStep.value.name)
);

const isCurrentStepLast = computed(() => stepIndex.value === steps.length - 1);

// Fixme: currentPet's key access is ensured by conditional checks but we could define this type better.
const currentPet: Ref<Record<string, any>> = ref({
  type: '',
  typeId: null,
  name: '',
  birthdate: '',
  icon: '' as StaticPictogramProps['name'],
});

const emit = defineEmits(['success', 'show-login-landing']);

const submit = async () => {
  isSubmitting.value = true;

  const pet: Pet = {
    typeId: currentPet.value.typeId,
    name: currentPet.value.name,
    birthdate: currentPet.value.birthdate,
  };

  const success = await petStore.createPet(pet);

  if (success) {
    isFinished.value = true;
  } else {
    error.value = true;
  }
  isSubmitting.value = false;
};

const next = () => {
  sendTrackingEvent(currentStep.value.name);
  if (isCurrentStepLast.value) {
    return submit();
  }
  currentStep.value = steps[stepIndex.value + 1];

  return true;
};

const sendTrackingEvent = (event: PetFormStep) => {
  const userId = user?.value?.id || '';
  gtm?.trackEvent(addPetEvent(userId, event));
};

const reset = () => {
  error.value = false;

  currentPet.value = {
    type: '',
    typeId: null,
    name: '',
    birthdate: '',
    icon: '' as StaticPictogramProps['name'],
  };

  isSubmitting.value = false;

  isFinished.value = false;

  [currentStep.value] = steps;
};

const leave = () => {
  emit('success');
};

const setPetTab = (event: any) => {
  currentPet.value.type = event.name;
  currentPet.value.typeId = event.id;
  currentPet.value.icon = event.icon;
  next();
};

const goBack = () => {
  if (stepIndex.value > 0) {
    error.value = false; // Reset error flag if going back
    currentStep.value = steps[stepIndex.value - 1];
  } else {
    emit('show-login-landing', true);
  }
};
</script>
