<template>
  <SidebarContent>
    <template #header>
      <h2 class="ml-4 text-xl">{{ $t('cart.cart') }}</h2>
      <div class="flex h-full items-center">
        <CartPictogram :cart-amount="itemCount" />
        <button class="h-full px-6" @click="toggleSidebar(false)">
          <DynamicPictogram name="times" />
        </button>
      </div>
    </template>
    <template #default>
      <!-- placeholder code -->
      <div v-if="itemCount">
        <div v-for="(item, index) in basketItems" :key="item.id">
          <PrescriptionItem
            class="my-2 px-4"
            :prescription-id="item.prescriptionId"
            :description="item.description"
            :index="index"
            :price="item.price"
            :quantity="item.quantity"
            :quantity-per-withdrawal="item.quantityPerWithdrawal"
            :brand-slug="item.brandSlug"
            :item-number="item.itemNumber"
          />
        </div>

        <div v-if="bcItemCount">
          <CartItem
            v-for="(item, index) in cart.items"
            :key="item.id"
            class="my-2 px-4"
            :product-id="item.productId"
            :variant-id="item.variantId"
            :cart-item-id="item.id"
            :title="item.name"
            :path="item.path"
            :image="item.defaultImageUrl"
            :regular-price="
              item.prices && item.prices.basePrice
                ? item.prices.basePrice.value
                : 0
            "
            :special-price="
              item.prices && item.prices.salePrice
                ? item.prices.salePrice.value
                : 0
            "
            :brand="item.brand"
            :categories="item.categories"
            :animal-type="item.animalType"
            :options="item.options"
            :quantity="{ value: item.quantity }"
            :tracking-context-data="{
              position: index + 1,
              list: 'Sidebar Cart',
            }"
            @product-click="toggleSidebar(false)"
          />
        </div>
        <div v-if="subscriptionItemCount">
          <CartItem
            v-for="(item, index) in subscriptionProducts"
            :key="item.variantId"
            class="my-2 px-4"
            :product-id="item.productId"
            :variant-id="item.variantId"
            :cart-item-id="item.variantId"
            :title="item.name"
            :path="item.path"
            :image="item.defaultImageUrl"
            :regular-price="
              item.prices && item.prices.basePrice
                ? item.prices.basePrice.value
                : 0
            "
            :special-price="
              item.prices &&
              item.prices.price.value < item.prices.basePrice.value
                ? item.prices.price.value
                : 0
            "
            :brand="item.brand"
            :categories="item.categories"
            :animal-type="item.animalType"
            :options="item.options"
            :quantity="{ value: item.quantity }"
            :tracking-context-data="{
              position: index + 1,
              list: 'Sidebar Cart',
            }"
            :frequency-in-days="item.frequencyInDays"
            @product-click="toggleSidebar(false)"
          />
        </div>
      </div>
      <p v-else class="p-6">{{ $t('cart.empty') }}</p>
    </template>
    <template v-if="itemCount" #footer>
      <PriceLine
        v-if="cartPromo && cartPromo.amount"
        :title="promoTitle"
        :price="cartPromoDiscountAmount"
        class="text-primary-default"
      />
      <PriceLine
        :title="$t('cart.total')"
        :subtitle="`(${itemCount} ${$t('product.products').toLowerCase()})`"
        :subtitle-classes="'text-sm text-black/54 font-medium'"
        :price="cartAmount"
        class="mb-4 pt-2 text-base font-semibold"
        :price-classes="'md:text-2xl text-lg md:text-primary-default md:font-title'"
        :title-classes="'text-black/87 text-md'"
      />
      <ShippingFreeProgressBar />

      <LoginBankIdButton
        v-if="subscriptionItemCount && !isLoggedIn"
        redirect-page="shop-checkout"
        label="cart.login_to_checkout"
      >
      </LoginBankIdButton>
      <Button
        v-else
        data-test="cart-go-to-checkout-button"
        class="mt-3"
        @click="showCartRecommendationsIfExists()"
      >
        {{ $t('cart.go_to_checkout') }}
      </Button>
    </template>
  </SidebarContent>
</template>

<script setup lang="ts">
import { promoTitle as formatPromoTitle } from '@/utils/checkout-utils';
import { usePrescriptionStore } from '~/stores/prescription';
import { useCartStore } from '~/stores/cart';
import { useSidebarStore } from '~/stores/sidebar';
import { useSubscriptionStore } from '~/stores/subscription';
import { HIDDEN_PROMO_CODES } from '#root/shared/config/discount-config';
import { useAuthStore } from '~/stores/auth';

const i18n = useI18n();
const localePath = useLocalePath();
const prescriptionStore = usePrescriptionStore();
const cartStore = useCartStore();
const sidebarStore = useSidebarStore();
const subscriptionStore = useSubscriptionStore();
const authStore = useAuthStore();

const { isLoggedIn } = storeToRefs(authStore);
const { subscriptionItemCount, subscriptionProducts } =
  storeToRefs(subscriptionStore);

const basketItems = computed(() => prescriptionStore.basketItems);
const cart = computed(() => cartStore.cart);
const cartRecommendations = computed(() => cartStore.cartRecommendations);

const itemCount = computed(() => cartStore.itemCount);
const bcItemCount = computed(() => cartStore.bcItemCount);

const cartAmount = computed(() => cartStore.cartAmount);
const cartPromoDiscountAmount = computed(
  () => cartStore.cartPromoDiscountAmount
);
const cartPromo = computed(() => cartStore.cartPromo);

const promoTitle = computed(() =>
  formatPromoTitle(
    cartPromo.value,
    i18n,
    HIDDEN_PROMO_CODES.includes(cartPromo.value?.code)
  )
);

const toggleSidebar = (shouldShow: boolean) =>
  sidebarStore.toggleSidebar(shouldShow);
const toggleCartRecommendations = (shouldShow: boolean) =>
  sidebarStore.toggleCartRecommendations(shouldShow);
const getCartRecommendations = () => cartStore.getCartRecommendations();

const goToCheckout = () => {
  toggleSidebar(false);
  navigateTo(localePath({ name: 'shop-checkout' }));
};

const showCartRecommendationsIfExists = async () => {
  await getCartRecommendations();

  if (cartRecommendations.value?.length) {
    toggleCartRecommendations(true);
  } else {
    goToCheckout();
  }
};
</script>
