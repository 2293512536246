<template>
  <div>
    <div v-if="legacy">
      {{ $t('footer.legacy_message') }}
    </div>
    <div v-else>
      <div class="flex flex-col lg:flex-row">
        <div class="order-2 flex basis-full flex-col lg:order-1 lg:basis-2/3">
          <p
            v-for="paragraph in $tm('footer.message_paragraphs')"
            :key="paragraph"
            class="mt-4 first-of-type:mt-0"
          >
            {{ $rt(paragraph) }}
          </p>
        </div>
        <div
          class="order-1 mb-8 flex basis-full justify-items-start md:pl-6 lg:order-2 lg:mb-0 lg:basis-1/3 lg:justify-items-center"
        >
          <FooterCountrySelector />
        </div>
      </div>
    </div>

    <div
      v-if="isPharmaEnabled && $i18n.locale === 'sv'"
      class="mx-auto mt-6 flex flex-col md:flex-row md:items-center md:justify-center"
    >
      <div class="inline-flex py-4 md:order-2 md:w-1/4 md:justify-end">
        <a
          href="https://lakemedelsverket.se/sv/handel-med-lakemedel/Anmalda-e-handlare/#A00026"
          target="_blank"
          class="shrink-0"
          aria-label="Länk till läkemedelsverket"
        >
          <img
            class="mr-8"
            :src="PharmaEULogo1"
            width="110"
            height="92"
            loading="lazy"
            alt="Klicka här för att kontrollera att webbplatsen är laglig"
          />
        </a>
        <a
          href="https://lakemedelsverket.se/sv/handel-med-lakemedel/Anmalda-e-handlare/#A00026"
          target="_blank"
          class="shrink-0"
          aria-label="Länk till läkemedelsverket"
        >
          <img
            :src="PharmaEULogo2"
            loading="lazy"
            width="110"
            height="92"
            alt="Klicka här för att kontrollera att webbplatsen är laglig"
          />
        </a>
      </div>
      <div
        class="flex flex-col md:order-1 md:mr-24 md:inline-flex md:w-3/4 md:flex-row"
      >
        <p class="py-4 md:mr-14">
          {{ $t('footer.pharma_message') }}
        </p>
        <a
          href="https://www.lakemedelsverket.se/sv/handel-med-lakemedel/apotek"
          target="_blank"
          class="flex items-center space-x-2 py-4 md:justify-center md:px-6"
          aria-label="Länk till läkemedelsverket"
        >
          <nuxt-img
            :src="ApotekLogoImg"
            class="h-12 align-middle"
            width="49"
            height="48"
            loading="lazy"
            alt="apotek logo"
          />
          <nuxt-img
            class="align-middle text-white md:min-w-[77px]"
            :src="ApotekLogoTextWhite"
            width="65"
            height="24"
            loading="lazy"
            alt="apotek logo"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ApotekLogoImg from '~/assets/logos/apotek-logo-img.svg?url';
import ApotekLogoTextWhite from '~/assets/logos/apotek-logo-text-white.svg?url';
import PharmaEULogo1 from '~/assets/logos/pharma-eu-logo-1.png?url';
import PharmaEULogo2 from '~/assets/logos/pharma-eu-logo-2.png?url';

defineProps({
  legacy: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { locale } = useI18n();

const { prescriptionLocales } = useRuntimeConfig().public.featureFlags;
const isPharmaEnabled = prescriptionLocales.includes(locale.value);
</script>
