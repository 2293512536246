<template>
  <div
    class="relative flex w-full justify-center bg-black p-1 text-white md:z-30 md:mb-0"
  >
    <div
      class="relative flex w-full whitespace-nowrap font-semibold motion-safe:overflow-x-hidden lg:hidden"
    >
      <div class="flex motion-safe:animate-marquee sm:w-full sm:animate-none">
        <span
          v-for="ribbonText in ribbonTextsMobile"
          :key="ribbonText"
          class="mx-auto px-4"
        >
          {{ ribbonText }}
        </span>
      </div>
      <div class="absolute top-0 flex motion-safe:animate-marquee2 sm:hidden">
        <span
          v-for="ribbonText in ribbonTextsMobile"
          :key="ribbonText"
          class="mx-auto px-4"
        >
          {{ ribbonText }}
        </span>
      </div>
    </div>

    <div class="hidden w-full max-w-6xl justify-between px-4 lg:flex">
      <div
        v-for="(ribbonTexts, i) in ribbonTextsDesktop"
        :key="i"
        class="flex flex-row content-start"
      >
        <span
          v-for="(ribbonText, index) in ribbonTexts"
          :key="index + '-' + ribbonText"
          class="flex items-center md:mx-1"
          :style="index % 2 !== 0 && 'opacity: 0.5'"
        >
          <a v-if="ribbonText.includes('@')" :href="`mailto:${ribbonText}`">
            {{ ribbonText }}
          </a>
          <span v-else>{{ ribbonText }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { locale, t } = useI18n();

const ribbonTextsMobile = computed(() => {
  const ribbonTexts = [
    t('ribbon.free_delivery').toUpperCase(),
    t('ribbon.delivery_time').toUpperCase(),
    t('ribbon.free_returns').toUpperCase(),
  ];

  if (locale.value === 'sv') {
    ribbonTexts.push(t('ribbon.get_prescriptions').toUpperCase());
  }

  return ribbonTexts;
});

const ribbonTextsDesktop = computed(() => {
  const ribbonTexts = [
    t('ribbon.free_delivery'),
    '|',
    t('ribbon.delivery_time'),
    '|',
    t('ribbon.free_returns'),
  ];

  if (locale.value === 'sv') {
    ribbonTexts.push('|');
    ribbonTexts.push(t('ribbon.get_prescriptions'));
  }

  return [ribbonTexts, [t('support.email'), '|', t('support.phone')]];
});
</script>
