<template>
  <button
    class="relative flex flex-col items-end justify-center p-4 focus:outline-none lg:hidden lg:p-6 lg:pr-5"
    aria-controls="mobile-menu"
    aria-label="menu"
    :aria-expanded="isNavMenuOpen"
    @click="headerMenu.setIsNavMenuOpen(true)"
  >
    <img
      class="size-6"
      src="~/assets/pictograms/static/menu.svg"
      alt="menu icon"
    />
  </button>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useGlobalMenuStore } from '~/stores/headerMenu';

const headerMenu = useGlobalMenuStore();
const { isNavMenuOpen } = storeToRefs(headerMenu);
</script>
