import { type MenuItem } from '../../../server/types/menu-types';

function findMenuItemsByPath(menuItems: MenuItem[], path: String): MenuItem[] {
  let foundItems: MenuItem[] = [];
  const directMatches = menuItems.filter((item) => item.path === path);
  foundItems = foundItems.concat(directMatches);
  menuItems.forEach((item) => {
    if (item.children) {
      const childMatches = findMenuItemsByPath(item.children, path);
      if (childMatches.length > 0) {
        foundItems = foundItems.concat(childMatches);
      }
    }
  });
  if (foundItems.length === 0) {
    const partialMatches = menuItems.filter(
      (item) => item.path && path.startsWith(item.path)
    );
    foundItems = foundItems.concat(partialMatches);
  }
  return foundItems;
}

function findMenuItemById(
  menuItems: MenuItem[],
  entityId: number
): MenuItem | undefined {
  let found = menuItems.find((item) => +item.entityId === entityId);
  if (!found) {
    menuItems.some((item) => {
      found = findMenuItemById(item.children || [], entityId);
      return !!found;
    });
  }
  return found;
}

function findParentMenuItem(
  menuItems: MenuItem[],
  activeItemId: number
): MenuItem | undefined {
  let parent: MenuItem | undefined;

  const findParent = (items: MenuItem[]): boolean =>
    items.some((item) => {
      const hasActiveChild = item.children?.some(
        (child) => +child.entityId === activeItemId
      );
      if (hasActiveChild) {
        parent = item;
        return true;
      }
      return item.children ? findParent(item.children) : false;
    });

  findParent(menuItems);
  return parent;
}

function isActiveItem(
  item: MenuItem,
  activeItem: MenuItem | undefined
): boolean {
  if (!activeItem) {
    return false;
  }
  return activeItem?.entityId === item.entityId;
}

function isFirstChildOfActive(
  item: MenuItem,
  activeItem: MenuItem | undefined
): boolean {
  if (!activeItem) {
    return false;
  }
  return (
    activeItem &&
    item.level === activeItem.level + 1 &&
    item.parentIds?.some((id) => +activeItem.entityId === parseInt(id, 10))
  );
}

function noActiveItem(activeItem: MenuItem | undefined): boolean {
  return !activeItem;
}

function isTopLevel(item: MenuItem): boolean {
  return item.level === 1;
}

function noActiveItemAndTopLevel(
  item: MenuItem,
  activeItem: MenuItem | undefined
): boolean {
  return noActiveItem(activeItem) && isTopLevel(item);
}

/* A function to force tailwind to include certain classes */
function tailwindSafeList(styles: String | undefined | null): String {
  if (styles?.includes('break-before-column')) {
    return 'break-before-column';
  }
  if (styles?.includes('break-after-avoid')) {
    return 'break-after-avoid';
  }
  if (styles?.includes('md:text-supporting-dark-3')) {
    return 'md:text-supporting-dark-3';
  }
  if (styles?.includes('text-supporting-dark-3')) {
    return 'text-supporting-dark-3';
  }
  if (styles?.includes('md:!pb-2')) {
    return 'md:!pb-2';
  }
  return '';
}

export {
  findMenuItemsByPath,
  findMenuItemById,
  findParentMenuItem,
  isActiveItem,
  isFirstChildOfActive,
  noActiveItemAndTopLevel,
  tailwindSafeList,
};
