<template>
  <SidebarContent>
    <template #header>
      <button
        class="absolute h-full px-6"
        @click="toggleCartRecommendations(false)"
      >
        <DynamicPictogram name="arrow" class="mr-2" />
      </button>
      <h2 class="ml-4 w-full text-center text-xl">
        {{ $t('cart_recommendations.cart_recommendations_title') }}
      </h2>
    </template>
    <template #default>
      <TransitionGroup name="fade-list">
        <ProductCard
          v-for="(cartRecommendation, index) in cartRecommendations"
          :id="parseInt(cartRecommendation.id)"
          :key="parseInt(cartRecommendation.id)"
          class="fade-list-item border-b md:!rounded-none"
          :slug="removeSlashes(cartRecommendation.path)"
          :horizontal="true"
          :title="cartRecommendation.title"
          :regular-price="cartRecommendation.base_price"
          :special-price="cartRecommendation.sale_price || ''"
          :is-cheapest-variant-price="
            cartRecommendation.is_cheapest_variant_price
          "
          :image="cartRecommendation.image || cartRecommendation.parentImage"
          image-classes="!mb-0 !h-32 sm:!mb-4 sm:!h-40"
          :brand="{
            entityId: +cartRecommendation.brandId,
            name: cartRecommendation.brandName,
            slug: removeSlashes(cartRecommendation.brandPath),
          }"
          :category-ids="
            cartRecommendation.categoriesId.map((id: string) => +id)
          "
          :tracking-data="{
            product: cartRecommendation.trackingData,
            position: index + 1,
            extraFields: { brand: cartRecommendation.brandName },
            list: 'Sidebar Cart Recommendations',
          }"
          :options="cartRecommendation.options"
          :variants="cartRecommendation.variants"
          :enable-variant-select="cartRecommendation.variants.length > 1"
          :is-cart-recommendation="true"
          :campaign="cartRecommendation.campaign"
          :campaign-badge-should-overflow="false"
          campaign-badge-size="sm"
          :is-available="cartRecommendation.is_available"
          :product-click-callback="
            () => sendLoop54ClickEvent(cartRecommendation.id)
          "
          :add-to-cart-callback="() => addToCartCallback(cartRecommendation.id)"
        />
      </TransitionGroup>
    </template>
    <template #footer>
      <ShippingFreeProgressBar />
      <Button
        data-test="cart-recommendations-go-to-checkout-button"
        class="mt-3"
        @click="goToCheckout()"
      >
        {{
          $t('cart_recommendations.cart_recommendations_continue_to_checkout')
        }}
      </Button>
    </template>
  </SidebarContent>
</template>

<script setup lang="ts">
import { useCartStore } from '~/stores/cart';
import { useSearchStore } from '~/stores/search';
import { useSidebarStore } from '~/stores/sidebar';
import { removeSlashes } from '#root/shared/utils/types/string-utils';

const localePath = useLocalePath();
const cartStore = useCartStore();
const sidebarStore = useSidebarStore();
const searchStore = useSearchStore();

const cartRecommendations: ComputedRef<null | any[]> = computed(
  () => cartStore.cartRecommendations
);

watch(cartRecommendations, (recommendations) => {
  if (!recommendations?.length) {
    goToCheckout();
  }
});

const toggleSidebar = (shouldShow: boolean) =>
  sidebarStore.toggleSidebar(shouldShow);
const toggleCartRecommendations = (shouldShow: boolean) =>
  sidebarStore.toggleCartRecommendations(shouldShow);
const getCartRecommendations = () => cartStore.getCartRecommendations();
const removeCartRecommendation = (cartRecommendationsId: number) =>
  cartStore.removeCartRecommendation(cartRecommendationsId);

const sendLoop54ClickEvent = (cartRecommendationsId: number) =>
  searchStore.sendLoop54ClickEvent(cartRecommendationsId);
const sendLoop54AddToCartEvent = (cartRecommendationsId: number) =>
  searchStore.sendLoop54AddToCartEvent(cartRecommendationsId);

const addToCartCallback = (cartRecommendationId: number) => {
  sendLoop54AddToCartEvent(cartRecommendationId);

  if (cartRecommendations.value?.length === 1) {
    // Fetch new recommendations when all current ones are added to cart
    getCartRecommendations();
  } else {
    removeCartRecommendation(cartRecommendationId);
  }
};

const goToCheckout = () => {
  toggleSidebar(false);
  navigateTo(localePath({ name: 'shop-checkout' }));
};
</script>
